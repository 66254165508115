<template>
  <div class="app-overlay">
    <!-- AppModal's -->
    <transition :name="overlayStore.activeAppModalTransition">
      <component
        :is="components[overlayStore.activeAppModal]"
        :options="overlayStore.activeAppModalOptions"
      />
    </transition>

    <!-- AppTray's -->
    <transition :name="overlayStore.activeAppTrayTransition">
      <component
        :is="components[overlayStore.activeAppTray]"
        :options="overlayStore.activeAppTrayOptions"
      />
    </transition>

    <!-- FlyIn's -->
    <transition :name="overlayStore.activeFlyInTransition">
      <component
        :is="components[overlayStore.activeFlyIn]"
        :options="overlayStore.activeFlyInOptions"
      />
    </transition>
  </div>
</template>

<script setup>
  import { computed, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import deepmerge from 'deepmerge'

  import { APP_MODAL, FLY_IN, APP_TRAY } from '~/lib/constants/overlay'
  import { useMainStore } from '~/stores/main'
  import { useOverlayStore } from '~/stores/overlay'

  // Stores
  const mainStore = useMainStore()
  const overlayStore = useOverlayStore()

  // Modals
  const QrCodeModal = defineAsyncComponent(() => import('~/components/qr-code-modal.vue'))
  const DeleteImagesModal = defineAsyncComponent(() => import('~/components/delete-images-modal.vue'))
  const UploadImagesModal = defineAsyncComponent(() => import('~/components/upload-images-modal.vue'))
  const EventDeleteModal = defineAsyncComponent(() => import('~/components/event-delete-modal.vue'))
  const EventUpgradeModal = defineAsyncComponent(() => import('~/components/event-upgrade-modal.vue'))
  const RemoveConnectorModal = defineAsyncComponent(() => import('~/components/remove-connector-modal'))
  const AdminDiscountCodeModal = defineAsyncComponent(() => import('~/components/admin-discount-code-modal.vue'))
  const ResetCustomEventSortingConfirmationModal = defineAsyncComponent(() => import('~/components/reset-custom-event-sorting-confirmation-modal.vue'))
  const LoginModal = defineAsyncComponent(() => import('~/components/login-modal.vue'))

  // AppTray's
  const SortMediaControlsTray = defineAsyncComponent(() => import('~/components/sort-media-controls-tray.vue'))

  // FlyIn's
  const GeneralNavigationFlyIn = defineAsyncComponent(() => import('~/components/general-navigation-fly-in.vue'))

  const components = {
    QrCodeModal,
    DeleteImagesModal,
    UploadImagesModal,
    EventDeleteModal,
    EventUpgradeModal,
    RemoveConnectorModal,
    AdminDiscountCodeModal,
    ResetCustomEventSortingConfirmationModal,
    LoginModal,
    SortMediaControlsTray,
    GeneralNavigationFlyIn,
  }

  const { isMobile } = storeToRefs(mainStore)
  const { desktop, mobile, settings } = storeToRefs(overlayStore)

  const desktopSettings = computed(() => desktop.value ? deepmerge(settings.value, desktop.value) : settings.value)
  const mobileSettings = computed(() => mobile.value ? deepmerge(settings.value, mobile.value) : settings.value)
  const overlaySettings = computed(() => isMobile.value ? mobileSettings.value : desktopSettings.value)

  watch(overlayStore, () => setCorrectOverlay())

  function setCorrectOverlay() {
    if (!overlaySettings.value) {
      return
    }

    if (overlaySettings.value.type === APP_MODAL) {
      overlayStore.setActiveAppModalTransition(overlaySettings.value?.transition)
      overlayStore.setActiveAppModalOptions(overlaySettings.value.options)
      overlayStore.setActiveAppModal(overlaySettings.value.component)
    }

    if (overlaySettings.value.type === APP_TRAY) {
      overlayStore.setActiveAppTrayTransition(overlaySettings.value?.transition ?? 'app-tray-transition')
      overlayStore.setActiveAppTrayOptions(overlaySettings.value.options)
      overlayStore.setActiveAppTray(overlaySettings.value.component)
    }

    if (overlaySettings.value.type === FLY_IN) {
      overlayStore.setActiveFlyInTransition(overlaySettings.value?.transition ?? 'fly-in-transition')
      overlayStore.setActiveFlyInOptions(overlaySettings.value.options)
      overlayStore.setActiveFlyIn(overlaySettings.value.component)
    }
  }
</script>

<style lang="scss">
  .app-overlay {
    position: relative;
    z-index: 2;
  }
</style>
